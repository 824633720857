let Index = () => import('./components/Index');
let Logout = () => import('./components/Logout');
let Users = () => import('./components/Users');
let Companies = () => import('./components/Companies');
let Accounts = () => import('./components/Accounts');
let Brands = () => import('./components/Brands');
let Products = () => import('./components/Products');
let Languages = () => import('./components/Languages');
let CMS = () => import('./components/Cms');
let Medias = () => import('./components/Medias');
let Transactions = () => import('./components/Transactions');
let Dashboard = () => import('./components/Dashboard');
let Configs = () => import('./components/Configs');
let CompaniesHistory = () => import('./components/CompaniesHistory');
let PosFilter = () => import('./components/PosFilter');
let PosInformation = () => import('./components/PosInformation');

export default {
	mode: 'history',

	routes: [
		{
			path: '/',
			component: Index
		},
		{
			path: '/logout',
			component: Logout
		},
		{
			path: '/users',
			component: Users
		},
		{
			path: '/companies',
			component: Companies
		},{
			path: '/companieshistory',
			component: CompaniesHistory
		},
		,{
			path: '/posfilter',
			component: PosFilter
		},{
			path: '/posinformation',
			component: PosInformation
		},
		{
			path: '/accounts',
			component: Accounts
		},
		{
			path: '/brands',
			component: Brands
		},
		{
			path: '/products',
			component: Products
		},
		{
			path: '/languages',
			component: Languages
		},
		{
			path: '/cms',
			component: CMS
		},
		{
			path: '/configs',
			component: Configs
		},
		{
			path: '/files',
			component: Medias,
			beforeEnter: (to, from, next) => {
				if(from.matched.length>0)
					next();
				else
					next('/')
			}
		},
		{
			path: '/transactions',
			component: Transactions
		},
		{
			path: '/dashboard',
			component: Dashboard
		},
		{
			path: '/languages/{id}',
			component: Languages
		},
	],
}