export default {
    en: {
    	logo: "https://image.flaticon.com/icons/png/512/196/196345.png",
        user: "User | Users",
        language: "Language | Languages",
        query: "Query for",
        search: "Search",
        insert: "Add",
        add: "Add",
        edit: "Edit",
        nextPage: "Next page",
        previousPage: "Previous page",
        currentPage: "Current page",
        page: "Page | Pages",
        sure: "Are you sure?",
        save: "Save",
        cancel: "Cancel",
        saved: "Saved with success!",
        success: "Operation successful!",
        name: "Name | Names",
        email: "E-mail | E-mails",
        country: "Country | Countries",
        password: "Password",
        verified: "Verified",
        all: "All",
        cms: "Content Variables",
        varid: "Variable Key",
        content: "Content",
        active: "Active",
        enabled: "Enabled",
        disabled: "Disabled",
        media: "Media | Medias",
        banner: "Banner | Banners",
        title: "Title",
        subtitle: "Subtitle",
        verse: "Verse",
        description: "Description / Content",
        linkTo: "Link to",
        post: "Post | Posts",
        missionary: "Missionary | Missionaires",
        missionaryWord: "Message of the day | Messages of the day",
        type: "Type",
        donation: "Donation | Donations",
        availableFrom: "Available From",
        member: "Pastor | Pastors",
        pastor: "Pastor | Pastors",
        event: "Event | Events",
        location: "Location | Locations",
        date: "Date",
        event_start: "Event starting at",
        event_end: "Event ending at",
        other_translations: "This variable in other languages",
        attendants: "Attendants",
        logout: "Logout",
        welcome: "Welcome",
        radio: "Radio | Radios",
        url: "URL",
        cover: "Cover | Covers",
        prayer: "Prayer | Prayers",
        message: "Message | Messages",
        reference: "Reference | References",
        select: "Select...",
        code: "Code",
        image: "Image | Images",
        order: "Order",
        config: "Setting | Settings",
        status: "Status",
        created_at: "Created at",
        value: "Value",
        successful: "Successful",
        not_successful: "Not successful",
        details: "Details",
        close: "Close",
        gallery: "Gallery | Galleries",
        files: "File | Files",
        roles: "Role | Roles",
        transaction: "Transaction | Transactions",
        company: "Company | Companies",
        pending: "Pending",
        fee: "Fee | Fees",
        account: "Account | Accounts",
        brand: "Brand | Brands",
        bank: "Bank | Banks",
        product: "Product | Products",
        agency: "Agency | Agencies",
        dashboard: "Dashboard",
        accept_null_values: "Accept blank fields",
    },
    pt: { 
    	logo: "https://image.flaticon.com/icons/png/512/196/196345.png",
        user: "Usuário | Usuários",
        language: "Idioma | Idiomas",
        query: "Busque por",
        search: "Buscar",
        insert: "Inserir",
        add: "Adicionar",
        edit: "Editar",
        nextPage: "Próxima página",
        previousPage: "Página anterior",
        currentPage: "Página atual",
        page: "Página | Páginas",
        sure: "Tem certeza?",
        save: "Salvar",
        cancel: "Cancelar",
        saved: "Salvo com sucesso!",
        success: "Operação efetuada com sucesso!",
        name: "Nome | Nomes",
        email: "E-mail | E-mails",
        country: "País | Países",
        password: "Senha",
        verified: "Verificado",
        all: "Todos",
        cms: "Variáveis de Conteúdo",
        varid: "Chave da Variável",
        content: "Conteúdo",
        active: "Ativo",
        enabled: "Ativado",
        disabled: "Desativado",
        media: "Mídia | Mídias",
        banner: "Banner | Banners",
        title: "Título",
        subtitle: "Subtítulo",
        verse: "Versículo",
        description: "Descrição / Conteúdo",
        linkTo: "Apontar link para",
        post: "Post | Posts",
        missionary: "Missionário | Missionários",
        missionaryWord: "Mensagem do dia | Mensagens do dia",
        type: "Tipo",
        donation: "Doação | Doações",
        availableFrom: "Disponível a partir de",
        member: "Pastor | Pastores",
        pastor: "Pastor | Pastores",
        event: "Evento | Eventos",
        location: "Localização | Localizações",
        date: "Data",
        event_start: "Evento inicia às",
        event_end: "Evento termina às",
        other_translations: "Esta variável em outras línguas",
        attendants: "Participantes",
        logout: "Sair",
        welcome: "Bem-vindo(a)",
        radio: "Rádio | Rádios",
        url: "URL",
        cover: "Imagem de Capa | Imagens de Capa",
        prayer: "Oração | Orações",
        message: "Mensagem | Mensagens",
        reference: "Referência | Referências",
        select: "Selecione...",
        code: "Código",
        image: "Imagem | Imagens",
        order: "Ordenação",
        config: "Configuração | Configurações",
        status: "Status",
        created_at: "Registrado em",
        value: "Valor",
        successful: "Efetuada com sucesso",
        not_successful: "Não efetuada",
        details: "Detalhes",
        close: "Fechar",
        gallery: "Galeria | Galerias",
        files: "Arquivo | Arquivos",
        roles: "Função | Funções",
        transaction: "Transação | Transações",
        company: "Adquirente | Adquirentes",
        companyhistory: "Companies History | Histórico dos Adquirentes",
        pending: "Pendente",
        fee: "Taxa | Taxas",
        account: "Conta | Contas",
        brand: "Bandeira | Bandeiras",
        bank: "Banco | Bancos",
        product: "Produto | Produtos",
        agency: "Agência | Agências",
        dashboard: "Dashboard",
        accept_null_values: "Aceitar importação de valores em Branco",
    },
}