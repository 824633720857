export default {

    created: function () {
        //console.log("Hello from General");
    },

    methods: {

        getLanguages() {
        	
            return new Promise(function(resolve, reject) {
                axios
                    .get("/api/languages", {
                        headers: {
                            Authorization:
                                "Bearer " + store.state.auth.access_token,
                        },
                        params: {
                            paginate: false,
                        },
                    })
                    .then((response) => {
                        store.set('languageList', response.data);
                        resolve();
                    });
            });
        },

        getCompanies() {
            
            return new Promise(function(resolve, reject) {
                axios
                    .get("/api/companies", {
                        headers: {
                            Authorization:
                                "Bearer " + store.state.auth.access_token,
                        },
                        params: {
                            paginate: false,
                        },
                    })
                    .then((response) => {
                        store.set('companyList', response.data);
                        resolve();
                    });
            });
        },

        getBrands() {
            
            return new Promise(function(resolve, reject) {
                axios
                    .get("/api/brands", {
                        headers: {
                            Authorization:
                                "Bearer " + store.state.auth.access_token,
                        },
                        params: {
                            paginate: false,
                        },
                    })
                    .then((response) => {
                        store.set('brandList', response.data);
                        resolve();
                    });
            });
        },

        getConfigs() {
            
            return new Promise(function(resolve, reject) {
                axios
                    .get("/api/configs", {
                        headers: {
                            Authorization:
                                "Bearer " + store.state.auth.access_token,
                        },
                        params: {
                            paginate: false,
                        },
                    })
                    .then((response) => {
                        store.set('configs', response.data);
                        resolve();
                    });
            });
        },

        saveConfigs() {
            
            return new Promise(function(resolve, reject) {
                axios
                    .post("/api/configs", store.state.configs, {
                        headers: {
                            Authorization:
                                "Bearer " + store.state.auth.access_token,
                        },
                        params: {
                        },
                    })
                    .then((response) => {
                        console.log(response);
                        resolve();
                    });
            });
        },

        getBanks() {
            
            return new Promise(function(resolve, reject) {
                axios
                    .get("/api/banks", {
                        headers: {
                            Authorization:
                                "Bearer " + store.state.auth.access_token,
                        },
                        params: {
                            paginate: false,
                        },
                    })
                    .then((response) => {
                        store.set('bankList', response.data);
                        resolve();
                    });
            });
        },

        getAccounts() {
            
            return new Promise(function(resolve, reject) {
                axios
                    .get("/api/accounts", {
                        headers: {
                            Authorization:
                                "Bearer " + store.state.auth.access_token,
                        },
                        params: {
                            paginate: false,
                            with: "bank"
                        },
                    })
                    .then((response) => {
                        store.set('accountList', response.data);
                        resolve();
                    });
            });
        },

        toast(msg) {
            this.$buefy.toast.open(msg)
        },

        success(msg) {
            this.$buefy.toast.open({
                message: msg,
                type: 'is-success'
            })
        },

        danger(msg) {
            this.$buefy.toast.open({
                duration: 5000,
                message: msg,
                type: 'is-danger',
                queue: false,
            })
        },

        can(what) {

            if(this.$root.store.user.role=='superadmin' || this.$root.store.user.role=='admin') {
                return true;
            } else {
                return false;
            }

        }

    },

    filters: {

        limitText(text, max){
            return (text ? text.slice(0, max) + (text.length > max ? "..." : "") : '');
        },

        fromNow: function (date, ignoreTZ=false) {
            if(date) {
                return getMomentDate(date, ignoreTZ).fromNow();
            } else {
                return '';
            }
        },

        calendar: function (date, ignoreTZ=false) {
            if(date) {
                return getMomentDate(date, ignoreTZ).calendar();
            } else {
                return '';
            }
        },

        toBrl(value){
            if(value) {
                let val =  parseFloat(value).toFixed(2).toString().replace('.', ',');
                var newVal = ','+val.split(",")[1];
                var charCount=-1;
                for(let char of val.split(",")[0].split("").reverse().join("")){
                    if(charCount ===2){
                        newVal = '.'+newVal;
                        charCount=0;
                    }else{
                        charCount++;
                    }

                    newVal = char+newVal;
                }
                return newVal;

            }
            return "0,00";
        },

    },
    
}

let getMomentDate = (date, ignoreTZ=false)=>{
    date = moment.tz(date, 'UTC');
    if(!ignoreTZ)
        date.tz('America/Sao_Paulo');
    return date
};