require("./bootstrap");
require("./classes");
require("./directives");

window.Vapor = require("laravel-vapor");

import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import VCalendar from "v-calendar";
Vue.use(VCalendar);

import routes from "./routes";

import Buefy from "buefy";
import "buefy/dist/buefy.css";
Vue.use(Buefy);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

window.Bugsnag = Bugsnag.start({
    apiKey: "0eede0daeb912f7e0513e87c809e6cf4",
    plugins: [new BugsnagPluginVue()]
});

Bugsnag.getPlugin("vue").installVueErrorHandler(Vue);

if (location.hostname.match("devorama")) {
    Vue.config.devtools = true;
}

import messages from "./messages";
import mixin_general from "./mixins/general";
import mixin_auth from "./mixins/auth";

Vue.component("navbar", () => import("./components/common/navbar.vue"));
Vue.component("login", () => import("./components/common/login.vue"));

// Create VueI18n instance with options
window.i18n = new VueI18n({
    locale: "pt", // set locale
    fallbackLocale: "pt",
    messages // set locale messagess
});

window.store = {
    debug: true,
    state: {
        isLoading: true,
        isLogged: false,
        auth: {},
        user: {},
        configs: {}
    },
    set(key, value) {
        this.state[key] = value;
    }
};

let app = new Vue({
    el: "#app",

    mixins: [mixin_general, mixin_auth],

    router: new VueRouter(routes),
    i18n,

    data: {
        privateState: {},
        store: store.state
    },

    created() {
        queue.push(this, [
            "loginFromLocalStorage",
            "checkTokenUser",
            "oauthRefreshToken",
            "getLanguages",
            "getCompanies",
            "getBrands",
            "getBanks",
            "getAccounts",
            "getConfigs"
        ]);
    }
});
