export default {

    created: function () {
        //console.log("Hello from Auth");
    },

    methods: {

        loginFromLocalStorage() {
            var self = this;

            return new Promise(function (resolve, reject) {

                var auth = JSON.parse(localStorage.getItem("store.state.auth"));

                var auth_since = moment().diff(JSON.parse(localStorage.getItem("store.state.auth_date")));
                const diffDays = Math.ceil(auth_since / (1000 * 60 * 60 * 24));

                if (diffDays > 2) {

                    localStorage.setItem("store.state.auth", JSON.stringify({}));
                    reject("LocalStorageToken invalid")
                }

                if (auth && auth.token_type == 'Bearer') {
                    store.state.auth = auth;
                    resolve();
                } else {
                    reject("LocalStorageToken invalid")
                }
            });
        },

        oauthToken(...args) {
            console.log(args[0])
            console.log(args[1])
            var self = this;
            return new Promise(function (resolve, reject) {

                // fetch("/oauth/token",
                //     {
                //         method: 'GET',
                //         headers: {
                //             'Content-Type': 'application/json',
                //             'Access-Control-Allow-Origin': '*',
                //         },
                //         body: JSON.stringify({
                //             grant_type: "password",
                //             client_id: 2,
                //             client_secret:
                //                 "tPtDTLslhBi4B4IA1HNzX9XVHlIOqHjVc96AcXOM",
                //             scope: "application",
                //             username: "admin@ongrace.com",
                //             password: "a11b22c33d44"
                //         })
                //     })
                //     .then(res => res.json())
                //     .catch(error => {
                //         if (error && error.response)
                //             console.error(error.response.data)
                //     })

                axios
                    .post("/oauth/token", {
                        grant_type: "password",
                        client_id: 2,
                        client_secret:
                            "tPtDTLslhBi4B4IA1HNzX9XVHlIOqHjVc96AcXOM",
                        scope: "application",
                        username: args[0],
                        password: args[1]
                    },
                        {
                        headers: {
                            "Content-Type": "application/json",
                            'Access-Control-Allow-Origin':'*'
                        }
                        }
                    )
                    .then((response) => {

                        if (response.data) {

                            if (response.data.error) {
                                reject(response.data.message)
                            }

                            store.state.auth = response.data;
                            localStorage.setItem("store.state.auth", JSON.stringify(response.data));
                            localStorage.setItem("store.state.auth_date", JSON.stringify(new Date().toUTCString()));
                            setTimeout(function () {
                                resolve();
                            }, 2000);
                        }
                    })
                    .catch((error) => {
                        alert("Wrong email or password");
                        console.error(error.response.data);
                        Bugsnag.notify(error);
                        reject(error.response.data);
                    });
            });
        },

        oauthRefreshToken() {
            var self = this;

            return new Promise(function (resolve, reject) {
                axios
                    .post("/oauth/token", {
                        grant_type: "refresh_token",
                        refresh_token: store.state.auth.refresh_token,
                        client_id: 2,
                        client_secret:
                            "tPtDTLslhBi4B4IA1HNzX9XVHlIOqHjVc96AcXOM",
                        scope: "application",
                    })
                    .then((response) => {

                        if (response.data) {

                            if (response.data.error) {
                                reject(response.data.message, "ERRO: 129")
                            }

                            store.state.auth = response.data;
                            localStorage.setItem("store.state.auth", JSON.stringify(response.data));
                            resolve();
                        }
                    })
                    .catch((error) => {
                        Bugsnag.notify(error);
                        reject(error.response.data, "ERRO: 130");
                    });
            });
        },

        checkTokenUser() {
            var self = this;

            return new Promise(function (resolve, reject) {
                axios
                    .get("/api/whoami", {
                        headers: {
                            Authorization:
                                "Bearer " + store.state.auth.access_token,
                        },
                    })
                    .then((response) => {
                        //console.log(response);
                        if (response.data) {

                            store.state.user = response.data;
                            store.state.isLogged = true;
                            resolve();

                        } else {
                            reject("No data coming from request");
                        }
                    })
                    .catch((error) => {
                        Bugsnag.notify(error);
                        console.log(error);
                        reject(error);
                    });
            });
        },

        refreshPage() {
            window.location.reload(true);
        },

        loginFromToken() {
            var self = this;

            store.state.auth.access_token = window.access_token;

            return new Promise(function (resolve, reject) {
                axios
                    .get("/api/whoami?with=cards,address", {
                        headers: {
                            Authorization:
                                "Bearer " + store.state.auth.access_token,
                        },
                    })
                    .then((response) => {

                        if (response.data) {
                            store.state.user = response.data;
                            store.state.isLogged = true;
                            resolve();
                        } else {
                            reject("No data coming from request");
                        }
                    })
                    .catch((error) => {
                        Bugsnag.notify(error);
                        console.log(error);
                        reject(error);
                    });
            });


        },

        /*
        checkIfIsAdmin() {
            var self = this;

            return new Promise(function(resolve, reject) {
                if(store.state.user.admin) {
                    store.state.isLogged = true;
                    resolve();
                } else {
                    store.state.user = null;
                    reject("User is not Admin");
                }
            });
        }
        */
    },


}